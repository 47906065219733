import { defineStore } from "pinia";
import axios from "axios";
import PocketBase from "pocketbase";
import { BASE_API_URL } from "../config";

import { mdiGaugeEmpty, mdiGaugeLow, mdiGauge, mdiGaugeFull, mdiCommentMultipleOutline, mdiClockFast, mdiStarOutline, mdiEmoticonExcitedOutline, mdiEmoticonSadOutline, } from "@mdi/js";

import { mapPeriodToDateRange, formatTime, getGraphColor } from '@/utils';
export const pb = new PocketBase(BASE_API_URL);

export const useMainStore = defineStore("main", {
  state: () => ({
    /* User */
    userName: pb.authStore.isValid ? pb.authStore.model.email : null,
    userEmail: pb.authStore.isValid ? pb.authStore.model.email : null,
    userActivated: pb.authStore.isValid ? pb.authStore.model.enabled : null,
    userAvatar: pb.authStore.isValid
      ? pb.getFileUrl(pb.authStore.model, pb.authStore.model.avatar)
      : null,

    pb: pb,
    /* Field focus with ctrl+k (to register only once) */
    isFieldFocusRegistered: false,

    /* Sample data (commonly used) */
    clients: [],
    history: [],
    companies: null,
    entities: null,
    overview: null,
    // Get values from local storage or use default values
    selectedEntity: localStorage.getItem('selectedEntity') ? JSON.parse(localStorage.getItem('selectedEntity')) : null,
    selectedCompany: localStorage.getItem('selectedCompany') ? JSON.parse(localStorage.getItem('selectedCompany')) : null,
    selectedPeriod: localStorage.getItem('selectedPeriod') || "All Time",
    reviewsByPlatform: null,
    sentimentByPlatform: null,
    reviewsByEmotion: null,
    sentimentOverTime: null,
    negativeTopics: null,
    positiveTopics: null,
    reviews: [],
    wordFrequency: null,
    reviewCountByPlatform: null,
    reviewVolumeAndSentiment: null,
    loading: false,
  }),
  actions: {
    async authRefresh() {
      await pb.collection("users").authRefresh();

      if (pb.authStore.isValid) {
        this.setUser(pb.authStore.model);
      }

    },
    setUser(payload) {
      if (payload === null) {
        this.userName = null;
        this.userEmail = null;
        this.userAvatar = null;
        return;
      }
      if (payload.name) {
        this.userName = payload.name;
      } else {
        this.userName = payload.email;
      }
      if (payload.email) {
        this.userEmail = payload.email;
      }
      if (payload.avatar) {
        this.userAvatar = payload.avatar
          ? pb.getFileUrl(payload, payload.avatar)
          : null;
      }

      if (payload.enabled) {
        this.userActivated = payload.enabled;
      }

    },

    async setSelectedEntity(payload) {
      this.selectedEntity = payload;
      localStorage.setItem('selectedEntity', JSON.stringify(payload)); // Save to local storage
      await this.fetchData();
    },

    async setSelectedCompany(payload) {
      this.selectedCompany = payload;
      localStorage.setItem('selectedCompany', JSON.stringify(payload)); // Save to local storage

      this.entities = this.selectedCompany["expand"]["entity(company)"].filter(entity => entity.entity_type !== "MAIN");
      this.entities.forEach(company => {
        company.label = company.name;
      });

      console.log(this.entities);
      this.entities.unshift({ 'id': "ALL", 'name': "All Branches", 'label': "All Branches" });
      this.selectedEntity = { 'id': "ALL", 'name': "All Branches", 'label': "All Branches" };

      localStorage.setItem('selectedEntity', JSON.stringify(this.selectedEntity)); // Save selectedEntity to local storage
      await this.fetchData();
    },

    async setSelectedPeriod(period) {
      this.selectedPeriod = period;
      localStorage.setItem('selectedPeriod', period); // Save to local storage
      await this.fetchData();
    },

    async fetchData() {

      this.loading = true;

      try {
        await Promise.all([
          this.fetchOverview(),
          this.fetchReviewsByPlatform(),
          this.fetchSentimentByPlatform(),
          this.fetchSentimentOverTime(),
          this.fetchReviewsByEmotion(),
          this.fetchWordFrequency(),
          this.fetchNegativeTopics(),
          this.fetchPositiveTopics(),
        ]);
        // All data fetching completed successfully
        // You can perform further actions after fetching all the data here
      } catch (error) {
        // Handle errors if any of the fetch operations fail
        console.error('Error fetching data:', error);
      }

      this.loading = false;

      this.setReviewVolumeAndSentiment();
    },

    fetch(sampleDataKey) {
      axios
        .get(`data-sources/${sampleDataKey}.json`)
        .then((r) => {
          if (r.data && r.data.data) {
            this[sampleDataKey] = r.data.data;
          }
        })
        .catch((error) => {
          alert(error.message);
        });
    },

    async fetchOverviewBySourceAndEntity() {
      const {startDate, endDate} = mapPeriodToDateRange(this.selectedPeriod);

      const formattedStartDate = startDate.toISOString(); // Convert dates to ISO format
      const formattedEndDate = endDate.toISOString();

      const overviewBySourceAndEntity = await pb.send(
        `/api/company/${this.selectedCompany.id}/overviewBySourceAndEntity/?fromDate=${formattedStartDate}&toDate=${formattedEndDate}`
      );

      return overviewBySourceAndEntity;

    },

    async fetchOverview() {

      const { startDate, endDate } = mapPeriodToDateRange(this.selectedPeriod);

      const formattedStartDate = startDate.toISOString(); // Convert dates to ISO format
      const formattedEndDate = endDate.toISOString();

      const overview = await pb.send(
        `/api/company/${this.selectedCompany.id}/overview/${this.selectedEntity.id}?fromDate=${formattedStartDate}&toDate=${formattedEndDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        },

      );
      let responseTimeValue, responseTimeSuffix;

      const formattedTime = formatTime(overview.Overview.avgResponseTime);

      if (formattedTime.isValid) {
        responseTimeValue = formattedTime.value;
        responseTimeSuffix = formattedTime.suffix;
      } else {
        responseTimeValue = '-';
        responseTimeSuffix = '';
      }

      const transformedData = {
        reviews: {
          total: overview.Overview.totalReviews,
          trend: `${overview.OverviewTrend.totalReviewsChange.toFixed(2)}%`,
          trendType: overview.OverviewTrend.totalReviewsChange > 0 ? 'up' : 'down',
          breakdown: {},
          color: "text-emerald-500",
          icon: mdiCommentMultipleOutline
        },
        sentiment: {
          total: Math.round(overview.Overview.avgSentiment * 100),
          trend: overview.OverviewTrend.avgSentimentChange ? `${overview.OverviewTrend.avgSentimentChange.toFixed(2) }%` : '-',
          trendType: overview.OverviewTrend.avgSentimentChange !== null ? (overview.OverviewTrend.avgSentimentChange > 0 ? 'up' : 'down') : '',
          breakdown: {},
          color:
            overview.Overview.avgSentiment < 0
              ? 'text-red-500'
              : 'text-green-500',

          icon:
            overview.Overview.avgSentiment < 0
              ? mdiEmoticonSadOutline
              : mdiEmoticonExcitedOutline

        },

        avgResponseTime: {
          total: responseTimeValue,
          trend: overview.OverviewTrend.avgResponseTimeChange ? `${overview.OverviewTrend.avgResponseTimeChange.toFixed(2)*-1}%` : '-',
          trendType: overview.OverviewTrend.avgResponseTimeChange !== null ? (overview.OverviewTrend.avgResponseTimeChange*-1 > 0 ? 'up' : 'down') : '',
          suffix: responseTimeSuffix,
          breakdown: {},
          color: "text-blue-500",
          icon: mdiClockFast
        },
        avgRating: {
          total: overview.Overview.avgRating,
          trend: `${overview.OverviewTrend.avgRatingChange.toFixed(2)}%`,
          trendType: overview.OverviewTrend.avgRatingChange > 0 ? 'up' : 'down',
          breakdown: {},
          icon:
            overview.Overview.avgRating >= 0 && overview.Overview.avgRating < 2
              ? mdiGaugeEmpty
              : overview.Overview.avgRating >= 2 && overview.Overview.avgRating < 3
                ? mdiGaugeLow
                : overview.Overview.avgRating >= 3 && overview.Overview.avgRating < 4
                  ? mdiGauge
                  : overview.Overview.avgRating >= 4 && overview.Overview.avgRating <= 5
                    ? mdiGaugeFull
                    : mdiGaugeEmpty,
          color:
            overview.Overview.avgRating >= 0 && overview.Overview.avgRating < 2
              ? "text-red-500"
              : overview.Overview.avgRating >= 2 && overview.Overview.avgRating < 3
                ? "text-yellow-500"
                : overview.Overview.avgRating >= 3 && overview.Overview.avgRating < 4
                  ? "text-orange-500"
                  : overview.Overview.avgRating >= 4 && overview.Overview.avgRating <= 5
                    ? "text-green-500"
                    : mdiGaugeEmpty,
        },
        ratings: {
          total: overview.Overview.totalRatings,
          trend: `${overview.OverviewTrend.totalRatingsChange.toFixed(2)}%`,
          trendType: overview.OverviewTrend.totalRatingsChange > 0 ? 'up' : 'down',
          breakdown: {},
          icon: mdiStarOutline,
          color: "text-blue-500"
        },
      };

      // Populate the breakdown by source
      overview.OverviewBySource.forEach((item) => {
        const source = item.source;
        transformedData.reviews.breakdown[source] = { value: item.totalReviews };
        transformedData.sentiment.breakdown[source] = { value: Math.round(item.avgSentiment * 100) };
        transformedData.avgRating.breakdown[source] = { value: item.avgRating };
        transformedData.ratings.breakdown[source] = { value: item.totalRatings };

        let value, suffix;

        const formattedTime = formatTime(item.avgResponseTime);

        if (formattedTime.isValid && item.avgResponseTime > 0) {
          value = formattedTime.value;
          suffix = formattedTime.suffix;
        } else {
          value = 0;
          suffix = '';
        }

        transformedData.avgResponseTime.breakdown[source] = { value: value, suffix: suffix };
      });

      this.overview = transformedData;
    },

    async fetchReviewsByPlatform() {
      const { startDate, endDate } = mapPeriodToDateRange(this.selectedPeriod);

      const formattedStartDate = startDate.toISOString(); // Convert dates to ISO format
      const formattedEndDate = endDate.toISOString();

      const reviewsByPlatform = await pb.send(
        `/api/company/${this.selectedCompany.id}/reviewsByPlatformByDay/${this.selectedEntity.id}?fromDate=${formattedStartDate}&toDate=${formattedEndDate}`
      );
      this.reviewsByPlatform = reviewsByPlatform;
      this.setReviewCountByPlatform(reviewsByPlatform);

    },


    async setReviewCountByPlatform() {

      const chartData = {
        platforms: [],
        dates: [],
        series: []
      }

      const platformSet = new Set();
      const dateSet = new Set();

      this.reviewsByPlatform.forEach(item => {
        platformSet.add(item.source);
        dateSet.add(item.date);
      });

      chartData.platforms = Array.from(platformSet);
      chartData.dates = Array.from(dateSet);

      // Convert to month/year labels if the date range is greater than 90 days
      if (chartData.dates.length > 90) {
        const dateFormat = new Intl.DateTimeFormat('en', { month: 'short', year: 'numeric' });
        const monthYearLabels = Array.from(new Set(Array.from(dateSet).map(date => dateFormat.format(new Date(date)))));

        const newSeriesData = {};
        chartData.platforms.forEach(platform => {
          if (!newSeriesData[platform]) {
            newSeriesData[platform] = [];
          }
          monthYearLabels.forEach(date => {
            newSeriesData[platform].push(0);
          });
        });

        this.reviewsByPlatform.forEach(item => {
          const indexDate = monthYearLabels.indexOf(dateFormat.format(new Date(item.date)));
          newSeriesData[item.source][indexDate] += item.ReviewCount;
        });

        chartData.dates = monthYearLabels;
        chartData.series = chartData.platforms.map(platform => ({
          label: platform.charAt(0).toUpperCase() + platform.slice(1),
          data: newSeriesData[platform],
          backgroundColor: getGraphColor(platform)
        }));


      } else {
        const seriesData = {};
        chartData.platforms.forEach(platform => {
          seriesData[platform] = Array(chartData.dates.length).fill(0);
        });


        this.reviewsByPlatform.forEach(item => {
          const indexDate = chartData.dates.indexOf(item.date);
          const indexPlatform = chartData.platforms.indexOf(item.source);
          seriesData[item.source][indexDate] = item.ReviewCount;
        });

        chartData.series = chartData.platforms.map(platform => ({
          label: platform.charAt(0).toUpperCase() + platform.slice(1),
          data: seriesData[platform],
          backgroundColor: getGraphColor(platform)
        }));
      }

      this.reviewCountByPlatform = chartData;

    },

    async setReviewVolumeAndSentiment() {


      const chartData = {
        platforms: [],
        dates: [],
        series: [],
        sentimentSeries: []
      };

      const platformSet = new Set();
  const dateSet = new Set();
  const avgSentimentArray = [];
  const dateArray = [];

  this.reviewsByPlatform.forEach(item => {
    platformSet.add(item.source);
    dateSet.add(item.date);
  });

  this.sentimentOverTime.forEach(item => {
    avgSentimentArray.push(Math.round(item.AvgSentiment * 100));
    dateArray.push(item.date);
  });

  chartData.platforms = Array.from(platformSet);

  const { startDate, endDate } = mapPeriodToDateRange(this.selectedPeriod);
  const differenceInMilliseconds = endDate - startDate;
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  if (differenceInDays > 90) {
    const dateFormat = new Intl.DateTimeFormat('en', { month: 'short', year: 'numeric' });
    const monthYearLabels = Array.from(new Set(Array.from(dateSet).map(date => dateFormat.format(new Date(date)))));

    const newSeriesData = [];

    monthYearLabels.forEach(date => {
      newSeriesData.push(0);
    });


    this.reviewsByPlatform.forEach(item => {
      const indexDate = monthYearLabels.indexOf(dateFormat.format(new Date(item.date)));
      newSeriesData[indexDate] += item.ReviewCount;
    });

    chartData.dates = monthYearLabels;
    chartData.series = [{
      name: 'Sentiment',
      type: 'line',
      data: avgSentimentArray
    },{
      name: 'Reviews',
      data: newSeriesData,
      type: 'bar'
    }];
  } else {
    const newSeriesData = [];

    const monthYearLabels = Array.from(new Set(Array.from(dateSet)));

    monthYearLabels.forEach(date => {
      newSeriesData.push(0);
    });


    this.reviewsByPlatform.forEach(item => {
      const indexDate = monthYearLabels.indexOf(item.date);
      newSeriesData[indexDate] += item.ReviewCount;
    });

    chartData.dates = dateArray;
    chartData.series = [{
      name: 'Sentiment',
      type: 'line',
      data: avgSentimentArray
    },{
      name: 'Reviews',
      data: newSeriesData,
      type: 'bar'
    }];
  }

  this.reviewVolumeAndSentiment = chartData;

    },

    async fetchSentimentByPlatform() {
      const { startDate, endDate } = mapPeriodToDateRange(this.selectedPeriod);

      const formattedStartDate = startDate.toISOString(); // Convert dates to ISO format
      const formattedEndDate = endDate.toISOString();

      const sentimentByPlatform = await pb.send(
        `/api/company/${this.selectedCompany.id}/reviewsByPlatformWithSentiment/${this.selectedEntity.id}?fromDate=${formattedStartDate}&toDate=${formattedEndDate}`
      );
      this.sentimentByPlatform = sentimentByPlatform;
    },


    async fetchWordFrequency() {

      const wordFrequency = await pb.collection('wordcloud').getFullList({
        filter: `entity.id = "${this.selectedEntity.id}"`,
      });

      if (wordFrequency.length === 0) {
        this.wordFrequency = [];
        return;
      }
      let words = wordFrequency[0].word_frequency;

      const colorMap = {
        "-1": "#FF6666",
        "0": "grey",
        "1": "#10b981"
      };

      const wordCloudArray = [];

      Object.keys(words).forEach(key => {
        const color = colorMap[key];
        Object.entries(words[key]).forEach(([text, weight]) => {
          wordCloudArray.push({
            text,
            weight,
            color: color || 'black'
          });
        });
      });

      this.wordFrequency = wordCloudArray;
    },

    async fetchReviewsByEmotion() {
      const { startDate, endDate } = mapPeriodToDateRange(this.selectedPeriod);

      const formattedStartDate = startDate.toISOString(); // Convert dates to ISO format
      const formattedEndDate = endDate.toISOString();

      const reviewsByEmotion = await pb.send(
        `/api/company/${this.selectedCompany.id}/reviewsByEmotion/${this.selectedEntity.id}?fromDate=${formattedStartDate}&toDate=${formattedEndDate}`
      );
      this.reviewsByEmotion = reviewsByEmotion;
      this.reviewsByEmotion.emotionLabels = reviewsByEmotion.map(
        (entry) => entry.Emotion
      );
      this.reviewsByEmotion.emotionSeries = [
        { data: reviewsByEmotion.map((entry) => entry.ReviewCount) },
      ];
    },

    async fetchSentimentOverTime() {
      const { startDate, endDate } = mapPeriodToDateRange(this.selectedPeriod);

      const formattedStartDate = startDate.toISOString(); // Convert dates to ISO format
      const formattedEndDate = endDate.toISOString();

      const sentimentOverTime = await pb.send(
        `/api/company/${this.selectedCompany.id}/sentimentOverTime/${this.selectedEntity.id}?fromDate=${formattedStartDate}&toDate=${formattedEndDate}`
      );
      this.sentimentOverTime = sentimentOverTime;
    },


    async fetchNegativeTopics() {
      const { startDate, endDate } = mapPeriodToDateRange(this.selectedPeriod);

      const formattedStartDate = startDate.toISOString(); // Convert dates to ISO format
      const formattedEndDate = endDate.toISOString();

      const negativeTopics = await pb.send(
        `/api/company/${this.selectedCompany.id}/negativeTopics/${this.selectedEntity.id}?fromDate=${formattedStartDate}&toDate=${formattedEndDate}`
      );
      if (negativeTopics) {
        negativeTopics.sort((a, b) => b.review_count - a.review_count);
        this.negativeTopics = negativeTopics;
      }
    },

    async fetchPositiveTopics() {
      const { startDate, endDate } = mapPeriodToDateRange(this.selectedPeriod);

      const formattedStartDate = startDate.toISOString(); // Convert dates to ISO format
      const formattedEndDate = endDate.toISOString();

      const positiveTopics = await pb.send(
        `/api/company/${this.selectedCompany.id}/positiveTopics/${this.selectedEntity.id}?fromDate=${formattedStartDate}&toDate=${formattedEndDate}`
      );
      if (positiveTopics) {
        positiveTopics.sort((a, b) => b.review_count - a.review_count);
        this.positiveTopics = positiveTopics;
      }
    },


    async fetchCompanies() {

      const companies = await pb.collection("company").getFullList({
        sort: "-created",
        expand: "entity(company)",
        filter: `user ~ '"${pb.authStore.model.id}"'`
      });

      companies.forEach(company => {
        company.label = company.name; // Replace "New Value" with the value you want to add
      });

      if (!this.selectedCompany) {
        this.selectedCompany = companies[0];
      }

      this.companies = companies;

      this.entities = this.selectedCompany["expand"]["entity(company)"].filter(entity => entity.entity_type !== "MAIN");
      this.entities.forEach(company => {
        company.label = company.name;
      });
      this.entities.unshift({ 'id': "ALL", 'name': "All Branches", 'label': "All Branches" });
      if (!this.selectedEntity) {
        this.selectedEntity = { 'id': "ALL", 'name': "All Branches", 'label': "All Branches" };
      }

      if (this.selectedEntity) {
        await this.fetchData();
      }
    },

    async fetchReviews() {
      if (this.selectedEntity.id === "ALL") {
        const reviews = await pb.collection("review").getList(1, 100, {
          sort: "-review_created",
          expand: "entity,topicreview(review).topic",
          filter: `entity.company.id = "${this.selectedCompany.id}"`
        });
        this.reviews = reviews.items;
      } else {
        const reviews = await pb.collection("review").getList(1, 100, {
          sort: "-review_created",
          expand: "entity,topicreview(review).topic",
          filter: `entity.id = "${this.selectedEntity.id}"`
        });

        this.reviews = reviews.items;

      }

    },
  },
});

